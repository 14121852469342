.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
body {
  font-family: sans-serif;
  text-align: center;
  height: 300vh;
  background-color: #3b6fe0;
  color: #fff;
  padding-top: 320px;
} */

.box {
  width: 60%;
  /* height: 30vw; */
  border-radius: 1em;
  /* background-color: #f9f07e; */
  margin-left: auto;

  margin-right: auto;
}

.magic {
  width: 30vw;
  height: 30vh;
  background-color: #000;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* #wave {
  position: relative;
  height: 70px;
  width: 600px;
  background: #e0efe3;
  transform: rotate(90deg);
}

#wave:before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 340px;
  height: 80px;
  background-color: white;
  right: -5px;
  top: 40px;
}

#wave:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 300px;
  height: 70px;
  background-color: #e0efe3;
  left: 0;
  top: 27px;
} */

.custom-shape-divider-bottom {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 115px;
}

.custom-shape-divider-bottom .shape-fill {
  fill: #2c2e35;
}

.custom-shape-divider-bottom2 .shape-fill2 {
  fill: #314cad;
}

.custom-shape-divider-bottom2 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 115px;
}

.custom-shape-divider-bottom2 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}
